.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100svh;
  width: 100vw;
  z-index: 20;
}

.ModalOverlay {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--rgb-white), 0.5);
}

.ModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  /* max-width: 90vw;
  max-height: 90vh; */
  z-index: 50;
  background-color: var(--color-white);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1024px) {
  .ModalWrapper.not-embedded {
    min-width: 30vw;
    max-width: 50vw;
    height: auto;
    max-height: 95vh;
    border-radius: .3em;
    box-shadow: 0 0 var(--spacing) calc(var(--spacing) * 0.25) rgba(var(--rgb-black), 0.2);
    border: 1px solid rgba(var(--rgb-black), 0.2);
  }
}

@media screen and (min-width: 1440px) {
  .ModalWrapper.not-embedded {
    min-width: 20vw;
    max-width: 40vw;
  }
}

@media screen and (min-width: 2560px) {
  .ModalWrapper.not-embedded {
    min-width: 10vw;
    max-width: 30vw;
  }
}

.Modal--partial .ModalWrapper {
  height: auto;
  bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media screen and (min-width: 1024px) {
  .Modal--partial .ModalWrapper {
    min-width: auto;
    max-width: none;
    max-height: none;
  }
}

@media screen and (min-width: 1440px) {
  .Modal--partial .ModalWrapper {
    min-width: auto;
    max-width: none;
  }
}

@media screen and (min-width: 2560px) {
  .Modal--partial .ModalWrapper {
    min-width: auto;
    max-width: none;
  }
}

.ModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2em;
  font-size: var(--font-size-small);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .ModalContent {
    padding: 3em;
  }

}

.ModalContent h1 {
  margin-bottom: 1.5em;
  font-size: var(--font-size-x-large);
}

.Modal--partial .ModalWrapper h1 {
  margin-bottom: 0;
}

.ModalContent p + p {
  margin-top: 1em;
}

.Modal .Logo {
  height: 2em;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2em;
}

.ModalBody {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ModalHeader {
  width: 100%;
  padding: 2em 2em 0;
}

@media screen and (min-width: 768px) {
  .ModalHeader {
    padding: 2em 3em 0;
  }

}

.large .CloseBtn {
  display: none !important;
}

.ModalHeader .CloseBtn {
  flex: auto;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.ModalHeader .CloseBtn .Icon {
  height: .75em;
  width: .75em;
}

.ModalFooter {
  padding: 0 2em 2em;
  font-size: var(--font-size-small);
}

.Modal h3 {
  text-transform: uppercase;
  font-size: var(--font-size-small);
  color: var(--color-grey);
  margin-bottom: 3ch;
}

.Modal hr {
  width: 100%;
  margin: 3ch 0;
  border: 0;
  border-top: 1px solid var(--color-grey-light);
}

.Modal .Button {
  width: 100%;
}

.Modal.large .ModalWrapper.not-embedded {
  max-width: 80vw;
  max-height: 90vh;
}

.Modal.large .ModalBody,
.Modal.large .ModalBody p {
  align-items: flex-start;
  text-align: left;
  padding-bottom: 1em;
}

.Modal.large h1 {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1.5em;
}

/* transition */

.ModalWrapper,
.ModalOverlay {
  transition: all .5s ease-in;
}

.Modal.in {
  transform: scale(1);
}

.Modal.out {
  /* very important otherwise prevent some clicks on the grid ! */
  pointer-events: none;
  transform: scale(0);
  transition: all 0s .5s;
}

.Modal.out .ModalOverlay {
  background: rgba(var(--rgb-black), 0);
}

.Modal.in .ModalOverlay {
  background: rgba(var(--rgb-black), .5);
}

.Modal.out .ModalWrapper {
  opacity: 0;
  transform: scale(.8) translateY(1000px);
}

.Modal.in .ModalWrapper {
  opacity: 1;
  transform: scale(1) translateY(0px);
}

.AppStoreBadge {
  margin-top: var(--spacing);
  background-color: var(--color-grey-lighter);
  padding: var(--spacing);
  text-align: center;
  border-radius: var(--radius);
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: var(--color-grey-dark);
  font-weight: normal;
  width: 100%;
}

.AppStoreBadge:hover {
  background-color: var(--color-yellow);
}

@media (prefers-color-scheme: dark) {
  .AppStoreBadge {
    background-color: rgb(var(--rgb-grey-dark));
    color: rgb(var(--rgb-white))
  }
  .AppStoreBadge:hover {
    background-color: var(--color-grey-light);
  }
}

.Modal ul {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: left;
}

.Modal ul li {
  list-style-type: none;
}

.Modal ul li + li {
  margin-top: 1em;
}

.Modal ul li span {
  margin-right: 1ch;
}

/* Splash */

.Modal--partial.Splash .ActionBtnGroup {
  display: none;
}

.Modal--full.Splash .ActionBtnGroup {
  display: flex;
}

.Splash .ActionBtnGroup {
  border-top: 1px solid var(--color-grey-light);
  justify-content: center;
  margin-top: calc(var(--spacing) * 4);
  padding-top: calc(var(--spacing) * 2);
}

.Splash .ActionBtnGroup .Button:first-child {
  margin-right: var(--spacing);
}