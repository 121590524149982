#root {
  position: relative;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
}

.App, .Main, .Content, .BoardWrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  flex-direction: row;
}

.App--Loading {
  flex-direction: column;
}

.Main, .BoardWrapper {
  flex-direction: column;
}

.Content {
  flex-direction: row;
}

.Board {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: auto;
  background-color: var(--color-yellow-light);
  overflow: hidden;
}

.BoardVertical {
  align-items: center;
}

@media (prefers-color-scheme: dark) {
  .Board {
    background-color: var(--color-grey-dark);
  }
}

@media screen and (min-width: 768px) {
  .Board {
    align-items: center;
    overflow: hidden;
  }

  .BoardHorizontal {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.Board-Notice {
  background-color: var(--color-yellow);
  padding: var(--spacing) calc(var(--spacing) * 2);
  text-align: center;
  font-size: var(--font-size-small);
}

@media (prefers-color-scheme: dark) {
  .Board-Notice {
    background-color: var(--color-green-dark);
  }
}



.MoreGrids-Card {
  padding: var(--spacing);
  border-radius: var(--radius);
  color: var(--color-grey-dark);
  font-size: var(--font-size-small);
  background-color: var(--color-white);
  text-align: left;
}

@media (prefers-color-scheme: dark) {
  .MoreGrids-Card {
    color: var(--color-black);
    background-color: var(--color-grey-dark);
  }
}

.MoreGrids-Card--Done {
  background-color: rgba(var(--rgb-black), .05);
  color: var(--color-grey);
}

@media (prefers-color-scheme: dark) {
  .MoreGrids-Card--Done {
    background-color: rgba(var(--rgb-white), .05);
    color: var(--color-grey-light);
  }

}

.MoreGrids-Card--Clickable {
  cursor: pointer;
}

.MoreGrids-Card--Clickable:hover {
  background-color: var(--color-grey-light);
  transition: transform 0.1s;
  transform: scale(var(--hover-scale));
}

@media (prefers-color-scheme: dark) {
  .MoreGrids-Card--Clickable:hover {
    color: var(--color-black);
  }
}

.MoreGrids-Card-Icon {
  margin-bottom: var(--spacing);
}

.MoreGrids-Card:not(:last-child) {
  margin-bottom: var(--spacing);
}

.MoreGrids-Card-Title {
  margin-bottom: .5em;
}

.MoreGrids-Card-Description {
  font-size: var(--font-size-small);
}

.Time {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.ActionUsername {
  margin-bottom: calc(var(--spacing) * 2);
}

.InputText {
  padding: 1ch 1.5ch;
  border: 1px solid var(--color-grey-light);
  border-radius: var(--radius);
  font-weight: bold;
  width: 100%;
  color: var(--color-grey-dark);
}

@media (prefers-color-scheme: dark) {
  .InputText {
    color: #fff;
  }
}