.Timer {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.TimerCountdown {
  cursor: pointer;
}

.Timer-Wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 1;
  font-size:  var(--font-size-small);
}

.Timer-CompetingTime {
  display: flex;
  color: var(--color-grey);
}

.Timer-CompetingTime-Separator {
  margin: 0 1ch;
  color: var(--color-grey);
}

.IconPause {
  cursor: pointer;
  font-size: 25px;
  padding-top: 4px;
  margin-right: 5px;
}

/* Use css animation for +20 secs penalty. Will silde up fading out */
@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.Penalty {
  color: var(--color-red);
  animation: slideUp 1s;
  margin-left: 5px;
}
