:root {
  --rgb-black: 0, 0, 0;
  --rgb-white: 255, 255, 255;
  --rgb-grey: 105, 111, 117;
  --rgb-grey-dark: 15, 21, 27;
  --rgb-grey-light: 210, 216, 222;
  --rgb-grey-lighter: 245, 245, 245;
  --rgb-yellow: 255, 221, 3;
  --rgb-yellow-light: 255, 251, 231;
  --rgb-red: 232, 34, 36;
  --rgb-blue-light: 159, 209, 255;
  --rgb-blue: 0, 133, 255;
  --rgb-red: 232, 34, 36;
  --rgb-red-lighter: 255, 235, 235;
  --rgb-red-light: 255, 204, 204;
  --rgb-red-rose: 202, 5, 77;
  --rgb-purple: 46, 11, 50;
  --rgb-green-light: 186, 222, 198;
  --rgb-green: 164, 212, 180;
  --rgb-green-dark: 59, 125, 81;

  --color-black: rgb(var(--rgb-black));
  --color-white: rgb(var(--rgb-white));
  --color-grey: rgb(var(--rgb-grey));
  --color-grey-dark: rgb(var(--rgb-grey-dark));
  --color-grey-light: rgb(var(--rgb-grey-light));
  --color-grey-lighter: rgb(var(--rgb-grey-lighter));
  --color-yellow: rgb(var(--rgb-yellow));
  --color-yellow-light: rgb(var(--rgb-yellow-light));
  --color-red: rgb(var(--rgb-red));
  --color-blue-light: rgb(var(--rgb-blue-light));
  --color-blue: rgb(var(--rgb-blue));
  --color-red: rgb(var(--rgb-red));
  --color-red-light: rgb(var(--rgb-red-light));
  --color-purple: rgb(var(--rgb-purple));
  --color-green: rgb(var(--rgb-green));
  --color-green-dark: rgb(var(--rgb-green-dark));

  --font-family: "Inter", Sans-Serif;
  --font-size: calc(1em + .7vw);
  --font-size-x-small: 60%;
  --font-size-small: 80%;
  --font-size-large: 105%;
  --font-size-x-large: 110%;
  --font-size-xx-large: 110%;
  --font-size-xxx-large: 120%;
  --font-size-xxxx-large: 135%;

  --line-height: calc(1.3em + 1vw);

  --spacing: 0.75em;

  --transition: 0.2s cubic-bezier(0.37, 1, 0.74, 1);

  --radius: 0.2em;

  --hover-scale: 1.05;

  --breakpoint-small: 25em;
  --breakpoint-medium: 50em;
  --breakpoint-large: 64em;
  --breakpoint-x-large: 75em;

  @media (prefers-color-scheme: dark) {
    --color-black: rgb(var(--rgb-white));
    --color-white: rgb(var(--rgb-black));
    --color-grey: rgb(var(--rgb-grey-light));
    --color-grey-dark: rgb(var(--rgb-grey-dark));
    --color-grey-light: rgb(var(--rgb-grey));
    --color-grey-lighter: rgb(var(--rgb-black));
    --color-red: rgb(var(--rgb-red-light));
    --color-red-light: rgb(var(--rgb-red-rose));
    --color-yellow: rgb(var(--rgb-green-dark));
    --color-blue-light: rgb(var(--rgb-green));
  }
}
