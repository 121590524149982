.Definitions--horizontal {
  display: flex;
  position: relative;
  background-color: var(--color-blue-light);
  min-height: 3em;
  padding: .5em;
  align-items: center;
  width: 100%;
}

.Definitions--vertical {
  display: flex;
  flex-direction: column;
  padding: calc(var(--spacing) * 2);
  overflow-x: auto;
  height: 100%;
  align-items: center;
}

.Definitions-Content {
  margin-top: auto;
  margin-bottom: auto;
}

.Definitions-Separator {
  font-size: var(--font-size-small);
  margin-bottom: calc(var(--spacing) * 2);
  color: var(--color-grey)
}

.Definitions-Separator--last {
  margin-top: calc(var(--spacing) * 2);
}

.Previous, .Next {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 35px;
  flex: none;
}

.Definitions-List--first {
  margin-bottom: calc(var(--spacing) * 1);
}

.Definition {
  display: flex;
  font-size: var(--font-size-small);
  color: var(--color-black);
}

.Definitions--horizontal .Definition {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(var(--rgb-black));
  margin: 0 var(--spacing);
}

.Definitions--vertical .Definition {
  border: 1px solid transparent;
  border-radius: var(--radius);
  margin: calc(var(--spacing) * 0.5) 0;
  padding: .5ch 1ch;
}

.Definitions--vertical .Definition:hover {
  border-color: var(--color-grey-light);
  cursor: pointer;
}

.Definition--selected {
  background-color: var(--color-blue-light);
  font-size: var(--font-size-small);
  color: var(--color-black);
}

.Definitions--vertical .Definition--selected:hover {
  border-color: transparent;
}

@media (prefers-color-scheme: dark) {
  .Definition--selected {
    color: var(--color-white);
  }
}

.Definitions-List--first .Definition:last-child {
  margin-bottom: 0;
}

.Definition-Counter {
  margin-right: var(--spacing);
  color: var(--color-grey);
  width: 2ch;
  text-align: right;
}

.Definitions--vertical .Definition--selected .Definition-Counter {
  color: rgb(var(--rgb-black));
}