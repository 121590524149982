.BtnGroup,
.ButtonGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--spacing);
}

.ButtonGroup--horizontal {
  flex-direction: row;
}

.ButtonGroup--horizontal .ActionBtn {
  flex: 1;
  margin: 0 var(--spacing) 0 0;
}

.ButtonGroup--horizontal .ActionBtn:last-child {
  margin-right: 0;
}

.BtnGroup .ActionBtn + .ActionBtn {
  margin-top: var(--spacing);
}

.ActionBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-grey-light);
  border-radius: var(--radius);
  padding: 1ch 1.5ch;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

.ActionBtn:hover {
  background-color: rgba(var(--rgb-grey-light), 0.5);
}

.Button--primary {
  background-color: var(--color-black);
  color: var(--color-white);
  border: 0;
}

@media (prefers-color-scheme: dark) {
  .Button--primary {
    background-color: var(--color-green-dark);
    color: var(--color-black);
  }
}

.Button--primary:hover {
  background-color: rgba(var(--rgb-black), 0.5);
}

@media (prefers-color-scheme: dark) {
  .Button--primary:hover {
    background-color: rgba(var(--rgb-white), 0.2);
    color: var(--color-black)
  }
}

.Button--active {
  background-color: rgba(var(--rgb-black), 0.15);
  color: var(--color-black);
  box-shadow: 0 0 5px rgba(var(--rgb-black), 0.2) inset;
  border: 1px solid rgba(var(--rgb-black), 0.4) !important;
}

.Button--active:hover {
  background-color: rgba(var(--rgb-black), 0.2);
}

.ActionBtn.disabled {
  background-color: var(--color-grey-light);
  color: var(--color-grey);
}

.Button {
  cursor: pointer;
  transition: transform 0.1s;
}

.Button:hover {
  transform: scale(var(--hover-scale));
}

.Button:active {
  background-color: rgba(var(--rgb-black), 0.25);
}

.Button--small {
  padding: 0.5ch 1ch;
  font-size: var(--font-size-small);
}