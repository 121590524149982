.ControlBar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: var(--spacing);
  background-color: var(--color-yellow);
}

.Content.is-success .ControlBar.is-embedded {
  padding-bottom: 2em;
}

@media screen and (min-width: 768px) {
  .ControlBar {
    padding: var(--spacing) calc(var(--spacing) * 2);
  }
}

.ControlBar-Actions .Timer {
  order: 1;
  flex: auto;
  font-size: var(--font-size-small);
}

.ControlBar-Actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  order: 2;
}

.ControlBar-Actions .Button {
  border: 0;
  background-color: rgba(var(--rgb-black), .2)
}

.ControlBar-Actions .Button + .Button {
  margin-left: 1ch;
}

.ControlBar-Actions .Label {
  color: var(--color-black);
  opacity: .75;
  margin-right: 1ch;
  font-weight: bold;
  font-size: var(--font-size-small);
}