/*
  Animation that jiggles up and down and left and right the success icon
*/

@keyframes jiggle {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-2px, -2px) rotate(10deg);
  }
  50% {
    transform: translate(0, 0) rotate(-10deg);
  }
  75% {
    transform: translate(2px, 2px) rotate(5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.Success-Icon {
  /* background-color: var(--color-black);
  border-radius: var(--radius); */
  /* width: 4em; */
  height: 2em;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-bottom: 3em;
  /* padding: var(--spacing) */
}

.Success-Icon img {
  height: 2em;
  animation: jiggle 0.4s;
  animation-iteration-count: 6;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: center;
}

.Success-ElapsedTime {
  font-size: var(--font-size-x-large);
}

.Success-More {
  margin-top: calc(var(--spacing) * 3);
  padding-top: calc(var(--spacing) * 3);
  border-top: 1px solid var(--color-grey-light);
}

.Success-More strong {
  color: var(--color-grey);
}

.Success-More .BtnGroup {
  margin-top: calc(var(--spacing) * 1);
}

.Success-Shared {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--color-yellow-light);
  padding: calc(var(--spacing) * 2);
  border-radius: var(--radius);
}

@media (prefers-color-scheme: dark) {
  .Success-Shared {
    background-color: var(--color-grey-dark);
  }
}

.Success-Shared .Icon {
  margin-bottom: calc(var(--spacing) * 2);
}

.Success-Comparison {
  width: 100%;
  margin-top: calc(var(--spacing) * 3);
  background-color: var(--color-grey-lighter);
  border-radius: var(--radius);
  padding: calc(var(--spacing));
}

@media (prefers-color-scheme: dark) {
  .Success-Comparison {
    background-color: var(--color-grey-dark);
  }
}

.Comparison-Graph {
  margin-bottom: 2em;
}

.Comparison-GraphBar-Wrapper {
  position: relative;
  margin-top: 1em;
}

.Comparison-GraphBar {
  width: 100%;
  height: 1em;
  background-color: var(--color-white);
  border-radius: var(--radius);
}

.Comparison-GraphBar-Item {
  position: absolute;
  top: 0;
}

.Comparison-GraphBar-Item--active {
  z-index: 10;
}

.Comparison-GraphBar-Item-Mark {
  width: 4px;
  border-radius: var(--radius);
  height: 1em;
  background-color: var(--color-grey);
  transform: translateX(-50%) scale(1.1);
  transition: transform 0.1s;
}

.Comparison-GraphBar-Item--active .Comparison-GraphBar-Item-Mark {
  transform: translateX(-50%) scale(1.2); 
}

.Comparison-GraphBar-Item--yours .Comparison-GraphBar-Item-Mark {
  background-color: var(--color-blue);
}

.Comparison-GraphBar-Item--theirs .Comparison-GraphBar-Item-Mark {
  background-color: var(--color-red);
}

.Comparison-GraphBar-ItemLabelWrapper {
  font-size: var(--font-size-x-small);
  margin-top: .5em;
  white-space: nowrap;
  padding: .5ch;
  border-color: transparent;
  position: absolute;
}

.Comparison-GraphBar-Item--active .Comparison-GraphBar-ItemLabelWrapper {
  background-color: rgba(var(--rgb-grey-light));
}

.Comparison-GraphBar-Item--yours .Comparison-GraphBar-ItemLabelWrapper {
  color: var(--color-blue);
}

.Comparison-GraphBar-Item--yours.Comparison-GraphBar-Item--active .Comparison-GraphBar-ItemLabelWrapper {
  background-color: rgba(var(--rgb-blue-light));
}

.Comparison-GraphBar-Item--theirs .Comparison-GraphBar-ItemLabelWrapper {
  color: var(--color-red);
}

.Comparison-GraphBar-Item--theirs.Comparison-GraphBar-Item--active .Comparison-GraphBar-ItemLabelWrapper {
  background-color: rgba(var(--rgb-red-light));
}

@media (prefers-color-scheme: dark) {
  .Comparison-GraphBar-Item--theirs.Comparison-GraphBar-Item--active .Comparison-GraphBar-ItemLabelWrapper {
    color: var(--color-black);
  }
}


.Success-Poll-Title {
  margin-bottom: 1em;
}
.Poll-Buttons {
  display: flex;
  font-size: var(--font-size-x-large);
  justify-content: center;
}

.Poll-Buttons .Button {
  flex: none;
  width: auto;
  margin: 0 var(--spacing);
}

.Success-Poll {
  width: 100%;
  margin-top: calc(var(--spacing) * 3);
  background-color: var(--color-grey-lighter);
  border-radius: var(--radius);
  padding: calc(var(--spacing));
}

@media (prefers-color-scheme: dark) {
  .Success-Poll {
    background-color: var(--color-grey-dark);
  }
}

.AppStoreBadge--Success {
  margin-top: calc(var(--spacing) * 3);
}