.Stats {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.StatsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.Block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
  min-height: 150px;
}

.BlockTitle {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.BlockValue {
  position: relative;
  font-size: 1.5em;
  font-weight: bold;
}

.BlockPercent {
  margin-top: -20px;
  margin-left: 20px;
  font-size: .5em;
  font-weight: normal;
}