.Header {
  display: flex;
  align-items: center;
  padding: var(--spacing) calc(var(--spacing) * 1.25);
  justify-content: space-between;
  font-size: var(--font-size-small);
  position: relative;
}

@media screen and (min-width: 768px) {
  .Header {
    padding: calc(var(--spacing) * 1.25) calc(var(--spacing) * 2);
  }
}

@media (prefers-color-scheme: dark) {
  .Header {
    border-color: var(--color-white);
  }
}

.Header .Motif-Infos {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
}

.Header h1 {
  display: none
}

@media screen and (min-width: 768px) {
  .Header h1 {
    display: block;
    margin-right: 1.5ch;
  }
}

.Header .Date {
  flex: none;
}

.Header .Logo {
  height: 1.5em;
  flex: none;
}

.Header .Timer {
  flex: none;
}

.LiveCounter-Wrapper {
  flex: none;
  color: var(--color-black);
  background-color: var(--color-grey-lighter);
  position: relative;
  padding: 0 var(--spacing);
  height: 1.5em;
  border-radius: var(--radius);
  display: flex;
  align-items: center;

}

@media (prefers-color-scheme: dark) {
  .LiveCounter-Wrapper {
    background-color: var(--color-grey-dark);
  }
}

.LiveCounter-Dot {
  width: .5em;
  height: .5em;
  background-color: var(--color-green-dark);
  border-radius: 100%;
  z-index: 1;
}

.LiveCounter-Counter {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.LiveCounter-Counter,
.LiveCounter-Suffix {
  margin-left: .5ch;
  color: var(--color-black);
  font-size: var(--font-size-small);  
}

.Header-Aside {
  flex: none;
  display: flex;
  align-items: center;
}

.Header-Aside .Button {
  margin-left: var(--spacing);
  display: none;
}

@media screen and (min-width: 1280px) {
  .Header-Aside .Button {
    display: block;
  }
}