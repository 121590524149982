.Panel {
  flex: none;
  width: 100%;
  height: 100svh;
  background-color: var(--color-grey-lighter);
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transition: all 0.3s ease;
}

.Panel-in {
  right: 0;
}

.Panel--out {
  right: -100vw;
}

.Panel,
.Main {
  transition: all 0.3s ease;
}

@media screen and (min-width: 1280px) {
  .has-Panel .Main {
    margin-right: 25vw;
  }
  .Panel {
    max-width: 25vw;
  }
  .Panel--out {
    right: -25vw;
  }
}

@media screen and (min-width: 1440px) {
  .has-Panel .Main {
    margin-right: 20vw;
  }
  .Panel {
    max-width: 20vw;
  }
  .Panel--out {
    right: -20vw;
  }
}

.Panel-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing);
  padding-top: calc(var(--spacing) * 2);
}

@media (prefers-color-scheme: dark) {
  .Panel-Header {
    border-top: 1px solid var(--color-white);
  }
}

.Panel-Header .CloseBtn {
  flex: auto;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.Panel-Header .CloseBtn .Icon {
  height: .75em;
  width: .75em;
}


.Panel-Title {
  font-size: var(--font-size-small);
  font-weight: bold;
}

.Panel-Header, 
.Panel-Content {
  padding-right: calc(var(--spacing) * 1.25);
  padding-left: calc(var(--spacing) * 1.25);
  margin-bottom: calc(var(--spacing) * 2);
}

@media screen and (min-width: 1024px) {
  .Panel-Header, 
  .Panel-Content {
    padding-right: calc(var(--spacing) * 2);
    padding-left: calc(var(--spacing) * 2);
  }
}

.Panel h3 {
  font-size: var(--font-size-small);
  margin-bottom: calc(var(--spacing) * 2);
  margin-top: calc(var(--spacing) * 2);
  opacity: .5;
  font-weight: normal;
}