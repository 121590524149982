html {
  background: var(--color-white);
  font-size: 16px;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--font-size) * 7.4);
}

body {
  align-items: stretch;
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  font-optical-sizing: auto;
  font-size: var(--font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.3;
  /* min-height: 100vh; */
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(var(--rgb-black), 0);
  text-rendering: optimizeLegibility;
}

::selection {
  background: rgba(var(--rgb-white), 0.2);
}

main {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-black);
  font-weight: 700;
}

a {
  color: var(--color-black);
  font-weight: 700;
  letter-spacing: normal;
  text-decoration: underline;
  text-decoration-thickness: 0.09em;
  text-underline-offset: 0.09em;
}

em,
i {
  font-style: italic;
}

strong,
b {
  color: var(--color-black);
  font-weight: 700;
}

.font-size-small {
  font-size: var(--font-size-small);
  letter-spacing: normal;
}

.font-size-large {
  font-size: var(--font-size-large);
}

.font-size-x-large {
  color: var(--color-white);
  font-size: var(--font-size-x-large);
}

.font-size-xx-large {
  color: var(--color-white);
  font-size: var(--font-size-xx-large);
}

.font-size-xxx-large {
  color: var(--color-white);
  font-size: var(--font-size-xxx-large);
}
