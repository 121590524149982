.GridWrapper {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .BoardHorizontal .GridWrapper {
    display: block;
    flex: none;
    padding: 0;
    position: sticky;
  }
}

@media screen and (min-width: 1024px) {
  .BoardHorizontal .GridWrapper {
    padding: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .BoardHorizontal .GridWrapper {
    padding: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .BoardHorizontal .GridWrapper {
    padding: 60px;
  }
}

.Grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .Grid {
    flex: none;
  }
}

.Row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.Cell {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  font-size: var(--font-size-xxx-large);
  height: 4em;
  width: 4em;
  overflow: hidden;
}

/* .Cell--opponent-success {
  background: linear-gradient(-45deg, var(--color-grey) 1%, var(--color-white) 1%, var(--color-white) 49%, var(--color-grey) 49%, var(--color-grey) 51%, var(--color-white) 51%, var(--color-white) 99%, var(--color-grey) 99%);
  background-size: 6px 6px;
  background-position: 50px 50px;
} */

.Cell--opponent-success {
  box-shadow: inset 0 0 0 .5ch rgb(var(--rgb-green-light));
}

.Cell--opponent-fail {
  box-shadow: inset 0 0 0 .5ch rgb(var(--rgb-red-lighter)); /* or light grey */
}

.Revealed-Mark,
.Revealed-Mark--opponent {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 1em;
  height: 1em;
}

.Revealed-Dot,
.Revealed-Dot--opponent {
  width: .5em;
  height: .5em;
  margin: auto;
  border-radius: 100%;
  background: rgb(var(--rgb-red));
}

.Revealed-Dot--opponent {
  opacity: 0.5;
}

.Cell.invalid:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid var(--color-red);
  transform: rotate(-45deg) translateY(-102%);
  transform-origin: center center;
  width: 250%;
}

@media (prefers-color-scheme: dark) {
  .Cell {
    background-color: var(--color-grey);
    color: var(--color-white);
    border: 1px solid rgb(var(--rgb-black));
  }
}

.Grid--Playing .Cell.selected {
  background: var(--color-yellow) !important;
  color: var(--color-black)
}

.Cell.row-selected {
  background: var(--color-blue-light);
}

.word-number {
  color: var(--rgb-black);
  font-size: var(--font-size-x-small);
  line-height: 1;
  position: absolute;
  top: .5ch;
  left: .5ch;
}

.Cell.black {
  background-color: var(--color-black);
}

@media (prefers-color-scheme: dark) {
  .Cell.black {
    background-color: var(--color-white);
  }
}