.Keyboard {
  width: 100%;
  /* animation: slideIn .5s ease-in forwards; */
}

.Keyboard.is-hidden {
  /* animation: slideOut .5s ease-out forwards; */
  display: none;
  pointer-events: none;
}

@keyframes slideIn {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}

@keyframes slideOut {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}

.hg-row:nth-child(3) {
  padding: 0 15px;
}

.hg-theme-default {
  background-color: var(--color-yellow);
  border-top: 1px solid rgb(var(--rgb-black), .1);
  box-sizing: border-box;
  overflow: hidden;
  padding: var(--spacing);
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
}

.Keyboard.is-embedded .hg-theme-default {
  padding-bottom: 2em;
}

.hg-theme-default .hg-button span {
  pointer-events: none;
  line-height: .7em;
}
.hg-theme-default button.hg-button {
  border-width: 0;
  font-size: inherit;
  outline: 0;
}
.hg-theme-default .hg-button {
  display: inline-block;
  flex-grow: 1;
}
.hg-theme-default .hg-row {
  display: flex;
}
.hg-theme-default .hg-row:not(:last-child) {
  margin-bottom: 5px;
}
.hg-theme-default .hg-row .hg-button-container,
.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 5px;
}
.hg-theme-default .hg-row > div:last-child {
  margin-right: 0;
}
.hg-theme-default .hg-row .hg-button-container {
  display: flex;
}
.hg-theme-default .hg-button {
  align-items: center;
  background: rgba(var(--rgb-white), 0.5);
  color: var(--color-black);
  border-bottom: 1px solid rgba(var(--rgb-black), 0.1);
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(var(--rgb-black), 0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px;
}

@media (prefers-color-scheme: dark) {
  .hg-theme-default .hg-button {
    background: rgba(var(--rgb-black), 0.3);
    border: 1px solid rgba(var(--color-black), 0.8);
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  }
}

.hg-theme-default .hg-button.hg-activeButton {
  background-color: rgba(var(--rgb-black), 0.1);
  border-color: transparent;
  box-shadow: inset 0 0 3px -1px rgba(var(--rgb-black), 0.3);
}
@media (prefers-color-scheme: dark) {
  .hg-theme-default .hg-button.hg-activeButton {
    background: rgba(var(--rgb-black), 0.5);
  }
}
.hg-theme-default.hg-layout-numeric .hg-button {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 33.3%;
}
.hg-theme-default .hg-button.hg-button-numpadadd,
.hg-theme-default .hg-button.hg-button-numpadenter {
  height: 85px;
}
.hg-theme-default .hg-button.hg-button-numpad0 {
  width: 105px;
}
.hg-theme-default .hg-button.hg-button-com {
  max-width: 85px;
}
.hg-theme-default .hg-button.hg-standardBtn.hg-button-at {
  max-width: 45px;
}
.hg-theme-default .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: #fff;
}
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
  max-width: 82px;
}
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: 60px;
}
.hg-candidate-box {
  background: var(--color-grey-light);
  border-radius: 5px;
  display: inline-flex;
  margin-top: -10px;
  max-width: 272px;
  position: absolute;
  transform: translateY(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
ul.hg-candidate-box-list {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
li.hg-candidate-box-list-item {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}
li.hg-candidate-box-list-item:hover {
  background: rgba(var(--color-black), 0.03);
  cursor: pointer;
}
li.hg-candidate-box-list-item:active {
  background: rgba(var(--color-black), 0.1);
}
.hg-candidate-box-prev:before {
  content: "◄";
}
.hg-candidate-box-next:before {
  content: "►";
}
.hg-candidate-box-next,
.hg-candidate-box-prev {
  align-items: center;
  color: #969696;
  cursor: pointer;
  display: flex;
  padding: 0 10px;
}
.hg-candidate-box-next {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.hg-candidate-box-prev {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.hg-candidate-box-btn-active {
  color: #444;
}
