.App > .Loading {
  height: 100vh;
}

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-small);
  color: var(--color-grey);
}

.Loading img {
  width: 2.5em;
  margin-bottom: 1em;
}

.Loading--small img {
  width: 1.5em;
  margin-bottom: 0;
}